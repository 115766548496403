<script>
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import i18n from '@src/i18n'
import { pick, isArray, isNumber, isNaN, debounce } from 'lodash'
import EventBus from '@src/event-bus'

export default {
  data() {
    return {
      split: {
        index: null,
        category: {},
      },

      questions: [],

      // If we get an error when fetching a question, we set an error message and show it to the user
      errorMessage: null,

      // The info field from the last answered question. It's used to show a notice to the user
      infoMessage: null,

      // When the last question has been answered we can populate the following
      // variables.
      scac: {
        major: null,
        minor: null,
        patch: null,
      },
      scacType: null,

      vatRate: null, // We update this when the user answers the last question
      vatRefund: null,

      canSave: false,

      integerAnswer: null,
      integerQuestionId: null,
      maximumReclaimAmount: null,

      unwatchStore: () => {},
    }
  },
  computed: {
    ...pick(expenseModalComputed, [
      'country',
      'scacWizardCurrentSplitIndex',
      'getCategory',
      'getCategoryTemporary',
    ]),
    isShowingErrorMessage() {
      return !!this.errorMessage
    },
    isShowingThanksYouAreDone() {
      return this.canSave && this.questions.length === 0
    },
    isShowingInfoMessage() {
      return this.scacType === 'MANUAL' && this.infoMessage
    },
    isShowingNoRefundMessage() {
      return this.scacType === 'NO_REFUND'
    },
    temporaryCategory() {
      return this.getCategoryTemporary(this.scacWizardCurrentSplitIndex)
    },
  },
  created() {
    // Start watching Vuex store
    this.unwatchStore = this.$store.watch(
      // Reactively watch this functions return value
      (state, getter) => {
        const index = this.scacWizardCurrentSplitIndex
        // If we have a temporary category in the vuex state, we will use that.
        const category =
          this.getCategoryTemporary(index) || this.getCategory(index) || {}
        // Return a primitive value
        return JSON.stringify({ index, category })
      },

      // Call this function when the value changes
      (json) => {
        this.split = JSON.parse(json)
      },

      // Options
      { immediate: true }
    )

    this.debouncedFetchMaxReclaimAmount = debounce(async (integerAnswer) => {
      if (integerAnswer === this.integerAnswer) {
        // Fetch is still relevant
        try {
          const { maximumReclaimAmount } = await this.fetchMaximumReclaimAmount(
            {
              questionId: this.integerQuestionId,
              integerAnswer,
            }
          )
          const question = this.questions.find(
            (question) => question.qid === this.integerQuestionId
          )
          this.scac = {
            major: question.major,
            minor: question.minor,
            patch: question.patch,
          }
          this.scacType = question.scacType
          this.vatRate = question.vatRate
          this.maximumReclaimAmount = maximumReclaimAmount
          this.canSave = true
        } catch (error) {
          console.log(error)
          this.resetIntegerAnswer()
        }
      }
    }, 400)

    // Get first question
    this.getFirstOrNextQuestion()
  },
  beforeDestroy() {
    this.unwatchStore()
  },
  methods: {
    ...pick(expenseModalMethods, [
      'updateExpenseScac',
      'updateScacInfo',
      'updateScacType',
      'updateVatRate',
      'updateVatRefund',
      'updateCategory',
      'updateMaximumReclaimAmount',
      'fetchMaximumReclaimAmount',
    ]),

    async getFirstOrNextQuestion(id) {
      const { queryId } = await this.$store.dispatch(
        'scacQuestions/fetchQuestion',
        {
          query: {
            country: id ? null : this.country,
            category: id ? null : this.split.category.key,
            language: i18n.locale,
            id,
          },
        }
      )

      // Get question
      const questionRaw = this.$store.getters['scacQuestions/item']({
        queryId,
      })

      // If we didn't get a question, we will check if we have an error response
      if (!questionRaw) {
        const response = this.$store.getters['scacQuestions/response'](queryId)
        const hasError =
          response &&
          response.user_message_code &&
          response.user_message_code === 'vattax.errors.question.not.found'
        if (hasError) {
          this.errorMessage = i18n.t('FAILED_TO_LOAD_SCAC_QUESTIONS', {
            interpolation: false,
            category: i18n.t(`CATEGORIES.${this.split.category.key}`),
            country: i18n.t(`ALPHA3_COUNTRY.${this.country}`),
          })
          return // Abort here
        }
      }
      this.errorMessage = null

      // Format question to what we need, and also fix bugs (from the SCAC engine)
      const question = this.fixBugsInQuestion(this.formatQuestion(questionRaw))

      if (question.replyType === 'INTEGER') {
        this.integerQuestionId = question.qid
      }

      // Sometimes we don't want to append the next question to the question array.
      const stopAsking =
        question.scacType === 'MANUAL' ||
        question.replyType === 'TEXT' ||
        (question.replyOptions.length === 0 && question.replyType !== 'INTEGER')

      if (stopAsking) {
        // We are done - save scac values and show save button
        this.scac = {
          major: question.major,
          minor: question.minor,
          patch: question.patch,
        }
        this.infoMessage = question.info
        this.scacType = question.scacType
        this.vatRate = question.vatRate
        this.canSave = true
      } else {
        this.questions = [...this.questions, { queryId, ...question }]
      }
      this.scrollToBottom()
    },

    async scrollToBottom() {
      // We need to wait for 2 renders, because we have computed properties that
      // is updated in 1st which triggers renders, and then we want to scroll
      // after the 2nd render
      await this.$nextTick()
      this.$nextTick(() => {
        const height = this.$refs.wrapper.clientHeight
        const container = this.$refs.wrapper
        this.$vuetify.goTo(height, {
          duration: 500,
          offset: 0,
          container,
        })
      })
    },

    formatQuestion(question) {
      const formattedQuestion = {
        qid: question.questionId, // Id for this question

        // Text strings shown in the UI
        text: question.questionTranslation, // The actual question
        info: question.scac.infoCode, // Info to the user, typically a notice to fix some problem

        // SCAC
        scacType: question.scac.scacType, // "CODE" | "MANUAL" | "NONE" | "NO_REFUND"
        major: question.scac.scacCodeMajor, // If this is the final question, we will use this scac value
        minor: question.scac.scacCodeMinor, // If this is the final question, we will use this scac value
        patch: question.scac.scacCodePatch, // If this is the final question, we will use this scac value

        // vat_percentage is a number that holds a single vat rate e.g. 12.5
        vatRate: question.scac.vatPercentage,

        // Should we reply to this question, and what are the reply options?
        replyType: question.replyType, // "TEXT" | "BOOLEAN" | "SELECT" | "INTEGER" ?? | "NONE"
        replyOptions: question.replyOptions.map((option) => {
          return {
            rid: option.replyId,
            nextQid: option.nextQuestionId,
            ordering: option.ordering,
            text: option.optionTranslation,
            info: option.scac.infoCode,
            scacType: option.scac.scacType, // "CODE" | "MANUAL" | "NONE" | "NO_REFUND"
            major: option.scac.scacCodeMajor, // 6
            minor: option.scac.scacCodeMinor, // 4
            patch: option.scac.scacCodePatch, // 1
            vatRate: option.scac.vatPercentage,
            vatRefund: isNumber(option.scac.vatRefundPercentage)
              ? option.scac.vatRefundPercentage
              : 100,
          }
        }),
      }

      return formattedQuestion
    },

    fixBugsInQuestion(question) {
      /**
       * Known bugs
       * 1. If option has a nextQid, scacType should be NONE
       * 2. If replyType is 'NONE', replyOptions should be empty
       * 3. If replyType is 'INTEGER', scacType should be CODE
       */

      if (question.replyType === 'NONE') {
        question.replyOptions = []
      }

      // Fixing reply options
      question.replyOptions = question.replyOptions.reduce((acc, option) => {
        const newOption = { ...option }

        // If we have a nextQid, it means we should ask more questions. The
        // scacType should be 'NONE'
        if (option.nextQid) {
          option.scacType = 'NONE'
        }

        return [...acc, newOption]
      }, [])

      if (question.replyType === 'INTEGER') {
        question.scacType = 'CODE'
      }

      return question
    },

    getQuestion({ qid, rid }) {
      let question, index
      if (qid) {
        question = this.questions.find((q) => q.qid === qid)
        index = this.questions.indexOf(question)
      } else if (rid) {
        question = this.questions.find((q) => {
          return q.replyOptions.find((r) => r.rid === rid)
        })
        index = this.questions.indexOf(question)
      }
      return { question, index }
    },

    getReplyOption(rid) {
      const { question } = this.getQuestion({ rid })
      let replyOption, index
      if (isArray(question.replyOptions) && question.replyOptions.length) {
        replyOption = question.replyOptions.find((r) => r.rid === rid)
        index = question.replyOptions.indexOf(replyOption)
      }
      return { replyOption, index }
    },

    onSelectAnswer(rid) {
      const { question, index: questionIndex } = this.getQuestion({ rid })
      const { replyOption } = this.getReplyOption(rid)
      // If user answered a question that wasn't the last, we remove the others
      this.questions = this.questions.filter((_, idx) => idx <= questionIndex)

      question.hasAnswer = true

      this.infoMessage = replyOption.info

      if (replyOption.nextQid) {
        // We are not done, get next question
        this.getFirstOrNextQuestion(replyOption.nextQid)
        this.canSave = false
        this.scacType = null
      } else {
        // We are done - save scac values and show save button
        this.scac = {
          major: replyOption.major || null,
          minor: replyOption.minor || null,
          patch: replyOption.patch || null,
        }
        this.scacType = replyOption.scacType || null
        this.vatRate = replyOption.vatRate || 0
        this.vatRefund = replyOption.vatRefund
        this.canSave = true
      }

      this.scrollToBottom()
    },

    onChangeIntegerAnswer(userInput) {
      const integerAnswer = parseInt(userInput)
      if (isNaN(integerAnswer)) {
        this.resetIntegerAnswer()
        return
      }
      const positiveAnswer = Math.abs(integerAnswer)
      this.integerAnswer = positiveAnswer
      this.canSave = false
      this.debouncedFetchMaxReclaimAmount(positiveAnswer)
    },

    resetIntegerAnswer() {
      this.integerAnswer = null
      this.maximumReclaimAmount = null
      this.canSave = false
    },

    onSaveScac() {
      this.updateCategory({
        category: this.split.category,
        splitIndex: this.split.index,
      }) // Save this category (not temporary anymore)

      this.updateExpenseScac({
        scac: this.scac,
        splitIndex: this.split.index,
      })

      this.updateScacInfo({
        information: this.infoMessage,
        splitIndex: this.split.index,
      })

      this.updateScacType({
        scacType: this.scacType,
        splitIndex: this.split.index,
      })

      if (
        this.vatRate === null ||
        (isNumber(this.vatRate) && !isNaN(this.vatRate))
      ) {
        this.updateVatRate({
          vatRate: this.vatRate,
          splitIndex: this.split.index,
        })
      }
      this.updateVatRefund({
        vatRefund: isNumber(this.vatRefund) ? this.vatRefund : 100,
        splitIndex: this.split.index,
      })
      this.updateMaximumReclaimAmount({
        maximumReclaimAmount: this.maximumReclaimAmount,
        splitIndex: this.split.index,
      })
      this.closeScacWizard()
      this.$amplitude.getInstance().logEvent('ANSWERED_SCAC_QUESTIONS', {
        category: this.split.category,
      })
    },

    onSaveCategory() {
      this.updateCategory({
        category: this.split.category,
        splitIndex: this.split.index,
      }) // Save this category (not temporary anymore)

      this.$amplitude.getInstance().logEvent('SAVED_SCAC_CATEGORY', {
        category: this.split.category,
      })

      this.closeScacWizard()
    },

    closeScacWizard() {
      EventBus.$emit('change-expense-modal-state', { toState: 'editor' })
    },
  },
}
</script>

<template>
  <div ref="wrapper" :class="$style.scacQuestions">
    <div
      v-if="isShowingErrorMessage"
      :class="[$style.infoBox, $style.hasError]"
    >
      <span>{{ errorMessage }}</span>
    </div>

    <!-- Only show scac heading if we don't have an error -->
    <h1 v-else :class="$style.scacHeading">{{ split.category.name }}</h1>

    <!-- Questions -->
    <div
      v-for="question in questions"
      :key="question.qid"
      :class="$style.scacQuestion"
    >
      <h2>{{ question.text }}</h2>

      <v-row>
        <v-col sm="12" cols="12" :class="$style.question">
          <v-select
            v-if="question.replyOptions && question.replyOptions.length"
            class="bulky"
            :class="$style.fadein"
            :menu-props="{ offsetY: true }"
            :items="question.replyOptions"
            item-text="text"
            item-value="rid"
            outlined
            :label="$t('ANSWER')"
            :prepend-inner-icon="
              question.hasAnswer
                ? 'mdi-category__scac_success'
                : 'mdi-category__scac_pending'
            "
            @change="onSelectAnswer"
          />

          <!-- Integer answer -->
          <v-text-field
            v-else
            v-model="integerAnswer"
            :label="$t('ANSWER')"
            outlined
            type="number"
            :placeholder="$t('INPUT_ENTER_NUMBER')"
            :class="$style.intergerInput"
            @keyup="(e) => onChangeIntegerAnswer(e.target.value)"
            @wheel="(e) => e.target.blur()"
          />
        </v-col>
      </v-row>
    </div>

    <!-- Info box informing the user that they are done. This is shown when the
    user don't have to answer any questions -->
    <div v-if="isShowingThanksYouAreDone" :class="$style.fadein">
      <h2>{{ $t('SCAC_DONE') }}</h2>
    </div>

    <!-- Info box notifying the user of what to do -->
    <div v-if="isShowingInfoMessage" :class="$style.infoBox">
      <span>{{ infoMessage }}</span>
    </div>
    <div v-if="isShowingNoRefundMessage" :class="$style.infoBox">
      <span>{{ $t('SCAC_TYPE_NO_REFUND_MESSAGE') }}</span>
    </div>

    <!-- Bottom button -->
    <div :class="$style.fixedToBottom">
      <!-- Thumbs up -->
      <div v-if="canSave" :class="$style.scacQuestionsSuccess">
        <div :class="$style.handAnimation">
          <img src="~@/src/assets/svg/scacThumpUpAnimation.svg" />
        </div>
        <span>{{ $t('THANK_YOU') }}</span>
        <span>- {{ $t('SCAC_ANSWERED') }}</span>
      </div>

      <!-- Save new category-->
      <v-btn
        v-if="isShowingErrorMessage && temporaryCategory"
        class="btn primary-btn center"
        :class="$style.savebtn"
        text
        @click="onSaveCategory"
      >
        {{ $t('SAVE_CATEGORY_BUTTON') }}
      </v-btn>

      <!-- Close wizard-->
      <v-btn
        v-else-if="isShowingErrorMessage && !temporaryCategory"
        class="btn primary-btn center"
        :class="$style.savebtn"
        text
        @click="closeScacWizard"
      >
        {{ $t('CLOSE_SCAC_WIZARD') }}
      </v-btn>

      <!-- Save scac-->
      <v-btn
        v-else
        class="btn primary-btn center"
        :class="$style.savebtn"
        text
        :disabled="!canSave"
        @click="onSaveScac"
      >
        {{ $t('SAVE_ANSWER_BUTTON') }}
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';
.fadein {
  animation: 0.85s cubic-bezier(0.22, 1.51, 0.28, 0.96) 0s 1 'fadePop';
}

.question {
  margin-bottom: 50px;
}

.handAnimation {
  width: 60px;
  height: 60px;
  margin: 0 auto 15px;
  animation: 2.3s cubic-bezier(0.22, 1.51, 0.28, 0.96) 0s 1 'handRotate';
}

.intergerInput {
  :global(.v-text-field__suffix) {
    color: rgba(51, 80, 134, 0.38) !important;
  }

  :global(.v-label:not(.v-label--active)) {
    right: 35px !important;
  }
}

.scacQuestions {
  width: 100%;
  padding: 50px 30px 270px 10px;
  margin-top: 0;
  overflow-y: scroll;
  text-align: left;
  :global(.v-input:not(.bulky) .theme--light.v-label) {
    background-color: $light-grey;
  }

  @include scrollbars;

  .scacHeading {
    margin: 40px 0 60px 10px;
    font-size: 22px;
    font-weight: 700;
    line-height: 29px;
    color: $dark;
  }
  h2 {
    padding-right: 10%;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: $dark;
  }

  .scacQuestionsSuccess {
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;
    animation: 0.85s cubic-bezier(0.22, 1.51, 0.28, 0.96) 0s 1 'fadePop';

    span {
      display: block;

      &:first-of-type {
        font-size: 36px;
      }
      &:last-of-type {
        color: $grey;
      }
    }
  }
}

.infoBox {
  padding: 15px;
  font-weight: 500;
  color: $dark;
  text-align: center;
  background-color: rgba($grey, 0.16);
  border-radius: 4px;
  animation: 0.85s cubic-bezier(0.22, 1.51, 0.28, 0.96) 0s 1 'fadePop';

  &.hasError {
    background-color: $yellow;
  }
}

.fixedToBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 40px;
  text-align: center;
  pointer-events: none; // Disable pointer events on the button wrapper
  background-image: linear-gradient(
    180deg,
    rgba(242, 244, 246, 0) 0%,
    $light-grey 60%
  );
  button {
    pointer-events: all; // Enable pointer events on the button
  }
}
</style>
