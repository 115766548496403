import axios from 'axios'
import { isString } from 'lodash'
import i18n from '@src/i18n'

const fallbackLanguage = 'en'

export const state = {
  byQid: {},
  cache: {
    queries: {},
  },
}

export const getters = {
  item:
    (state) =>
    ({ queryId, qid }) => {
      const language = i18n.locale || fallbackLanguage
      if (qid) {
        return (state.byQid[qid] && state.byQid[qid][language]) || null
      } else {
        // Check if we have a cached successful response for this queryId
        const cacheHit = state.cache.queries[queryId]
        if (
          !cacheHit ||
          !cacheHit.success ||
          !isString(cacheHit.response.data)
        ) {
          return null
        }
        // We have data in cache!
        return state.byQid[cacheHit.response.data][language]
      }
    },
  response: (state) => (queryId) => {
    // Check if we have a cached response for this queryId
    const cacheHit = state.cache.queries[queryId]
    if (!cacheHit) {
      return null
    }
    // We have data in cache!
    return cacheHit.response
  },
}

export const mutations = {
  // The ADD_QUERY mutation is called in order to update the state with a
  // query and its reponse.
  ADD_QUERY(state, { queryId, query, response, success }) {
    const queryDetails = {
      createdAt: Date.now(),
      id: queryId,
      query,
      success,
      response: success
        ? {
            data: String(response.data.qid),
          }
        : response,
    }
    state.cache.queries[queryId] = queryDetails
    if (success) {
      state.byQid[response.data.qid] = {
        ...(state.byQid[response.data.qid] || {}),
        [query.language]: response.data,
      }
    }
  },
}

export const actions = {
  async fetchQuestion(
    { commit, state, rootGetters },
    { query, useCache = true }
  ) {
    if (!query.language) {
      if (process.env.NODE_ENV !== 'production') {
        throw new Error(`query.language may not be ${query.language}.`)
      } else {
        query.language = fallbackLanguage
      }
    }

    const queryId =
      query.countryCode && query.language
        ? `Q_${JSON.stringify(query)}`
        : `Q_ID${JSON.stringify(query)}`

    // Check if we have cached this
    const cacheHit = useCache && state.cache.queries[queryId]
    if (!cacheHit || !cacheHit.success) {
      const callScacAPI = async ({ country, category, id }) => {
        try {
          const url = [
            rootGetters['general/getVatQuestionServiceUrl'],
            'questions/tree',
            country,
            category,
            id,
          ]
            .filter(Boolean)
            .join('/')
          const config = {
            headers: { 'Accept-Language': query.language || fallbackLanguage },
          }
          const response = await axios.get(url, config)
          response.data = { data: response.data }
          return response
        } catch ({ response }) {
          return response
        }
      }

      // Call the API
      const { country, category, id } = query
      const response = await callScacAPI({ country, category, id })

      // Handle the response
      commit('ADD_QUERY', {
        queryId,
        query,
        response: response.data,
        success: response.status >= 200 && response.status <= 299,
      })
    }

    return { queryId }
  },
}
